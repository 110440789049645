/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .bg-stripes-cyan {
    background-color: #22d3ee1a;
    background-image: linear-gradient(135deg, #06b6d490 10%, transparent 0, transparent 50%, #06b6d490 0, #06b6d490 60%, transparent 0, transparent);
    background-size: 7.07px 7.07px;
  }
}
:root {
  --aqua: #52ffbf;
  --lightpink: #ff57b3;
  --lighterpink: #fa93cc;
  --offwhite: #94a3b8;
  --white: #fff;
  --transparent: rgb(255, 255, 255, 0.1);
  --black: #000;
  --clearBlack: rgb(0, 0, 0, 0.3);
  --blue: #007bff;
  --indigo: #6610f2;
  --pastelPurple: #af5fff;
  --purple: #6f42c1;
  --midnightpurple: #11091f;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --gold: #faca39;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --newgreen: #66f29e;
  --neongreen: #7ee787;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --lightgrey: #888888;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --dark: #343a40;
  --darker: #2a2c2e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
}
html {
  background-color: rgb(17,23,43);
}
div, li, p, h1, h2, h3, input {
  color: var(--offwhite);
}
